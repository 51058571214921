*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  scroll-behavior: smooth;
  word-wrap: break-word; 
}
.greyvalourFont1{
  font-family: 'Bungee', cursive;
  font-size: 32px;
  font-weight: 600;
  padding-left: 20px;
}
.greyvalourFont2{
  font-family: 'Monoton', cursive;
  font-size: 32px;
  /* font-weight: 600; */
  padding-left: 20px;
}
.greyvalourFont3{
  font-family: 'Orbitron', sans-serif;
  font-size: 32px;
  font-weight: 600;
  padding-left: 20px;
}
.greyvalourFont31{
  font-family: 'Orbitron', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.logoSize img{
  max-width: 100px;
}
.logoSize_nav img{
  max-width: 35px;
}
.logoSize{
  max-width: 300px;
}
.greyvalour{
  color: #4D4D4D;
}

a{
  text-decoration: none !important;
}
textarea{
  outline: none;
}

button{
  border: none !important;
}
input,select{
  outline: none !important;
}
body {
  margin: 0;
  /* font-family: 'Fredoka', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1440px;
  margin: 0 auto !important;
}
.fonts1{
  font-family: 'Climate Crisis', cursive;
}
.fonts10{
    font-family: 'Tilt Prism', cursive;
}
.fonts9{
    font-family: 'Sono', sans-serif;
}
.fonts8{
    font-family: 'Sacramento', cursive;
}
.fonts7{
    font-family: 'Rubik Iso', cursive;
}
.fonts6{
    font-family: 'Rampart One', cursive;
}
.fonts5{
    font-family: 'Raleway', sans-serif;
}
.fonts4{
    font-family: 'Permanent Marker', cursive;
}
.fonts2{
  font-family: 'Fredoka One', cursive;
}
.fonts3{
  font-family: 'Pacifico', cursive;
}
.pl_05{
    padding-left: 5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color{
  color: #2E343B;
}
a{
  text-decoration: none;
  color: #2E343B;
}
img{
  max-width: 100%;
}

/* general.............................................. */
.d_block{
  display: block;
}

.pt_15{
  padding-top: 15px;
}
.border_right{
  border-right: 1px solid #f1f1f1;
}
.btn_info{
  background-color: #007BFF;
  padding: 7px 20px;
  color: #fff;
  border-radius: 6px;
  font-weight: 700;
}
.btn{
  background-color: #f1f1f1;
  padding: 7px 20px;
  border-radius: 6px;
  font-weight: 700;
}
.btn_danger{
  background-color: #DC3545;
  padding: 7px 20px;
  border-radius: 6px;
  font-weight: 700;
}

.equo_Two{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.bg_warning{
  background-color: #FFF3CD;
}
.bg_white{
  background-color: rgb(255,255,255,.7);
  z-index: 100000;
}
.bg_primary{
  background-color: #CCE5FF;
}
.bg_success{
  background-color: #D4EDDA;
}
.bg_info{
  background-color: #007BFF;
}
.bg_blur{
  background-color: #f2f2f2;
}
.my_15{
  margin: 5px 0;
}
.w_100{
  width: 100%;
}
.warning{
  color: #FFC107;
}
.success{
  color: #28A745;
}
.danger{
  color: #DC3545;
}
.z_index{
  z-index: 5;
}
.dark{
  color: black !important;
}
.pointer{
  cursor: pointer;
}

.d_none{
  display: none;
}
.right_auto{
  margin-right: auto;
}
.left_auto{
  margin-left: auto;
}
.shadow-smup {
  box-shadow: 0 .15rem .25rem rgba(0,0,0,.075);
}
div.sticky_top{
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 0 !important;
  z-index: 696;
}

.shadow_sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.shadow_lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.Rounded{
  border-radius: 10px;
}
.Rounded_lg{
  border-radius: 15px;
}
.Rounded_sm{
  border-radius: 5px;
}
.border_right{
  border-right: 1px solid #fff;
}
.float_right{
  float: right;
}

.center2{
  margin: auto;
}
.center3{
  margin: auto 0;
}

.centerx{
  margin: 0 auto;
}
.flex{
  display: flex;
}
/* .ul{
  list-style: none;
}
.ul li:before {
  content: '✓';
  color: #fff;
  display: inline-block; 
  width: 1em ;
  margin-left: -1em;
  margin-right: 30px;
} */
.white{
  color: #fff !important;
}
.font_20_b{
  font-size: 20px;
  font-weight: 800;
}
.font_18_b{
  font-size: 18px;
  font-weight: 800;
}
.font_12{
  font-size: 13px;
}
.font_12_b{
  font-size: 13px;
  font-weight: 800;
}
a:hover{
  /* color: #E5E5E5; */
  text-decoration: none;
}
.h_min{
  min-height: 160px;
}


/* general........................................ */
.pt_05{
  padding-top: 5px;
}
.h_100{
  height: 100% !important;
}
.relative{
  position: relative;
}
.mt_05{
  margin-top: 5px;
}
.mt_15{
  margin-top: 15px;
}
.mt_3{
  margin-top:40px;
}
.mt_4{
  margin-top: 50px;
}
.mt_5{
  margin-top: 60px;
}
.mt_6{
  margin-top: 70px;
}
.mt_7{
  margin-top: 80px;
}
.mt_8{
  margin-top: 90px;
}
.mt_9{
  margin-top: 100px ;
}
.pt_6{
  padding-top: 70px;
}
.pt_7{
  padding-top: 80px;
}
.pt_8{
  padding-top: 90px;
}
.pt_9{
  padding-top: 100px;
}
.mt_2{
  margin-top:20px;
}
.font_11{
  font-size: 11px;
}
.font_10{
  font-size: 10px;
}
.font_112{
  font-size: 12px;
}
.font_13{
  font-size: 13px;
}
.font_14{
  font-size: 14px;
}
.font_15{
  font-size: 15px;
}
.font_16{
  font-size: 16px;
}
.font_18{
  font-size: 18px;
  
}
.font_20{
  font-size: 20px;
  
}
.font_22{
  font-size: 22px;
  
}
.font_24{
  font-size: 24px;
  
}
.font_26{
  font-size: 26px;
  
}
.font_28{
  font-size: 28px;
  
}
.font_32{
  font-size: 32px;
  
}
.font_34{
  font-size: 34px;
  
}
.font_35{
  font-size: 35px;
  
}
.font_35{
  font-size: 35px;
  
}

.font_36{
  font-size: 36px;
  
}
.font_38{
  font-size: 38px;
  
}
.font_40{
  font-size: 40px;
  
}
.font_45{
  font-size: 45px;
  
}
.font_50{
  font-size: 50px;
  
}
.bold1{
  font-weight: 100;
}
.bold2{
  font-weight: 200;
}
.bold3{
  font-weight: 300;
}
.bold4{
  font-weight: 400;
}
.bold5{
  font-weight: 500;
}
.bold6{
  font-weight: 600;
}
.bold7{
  font-weight: 700;
}
.bold8{
  font-weight: 800;
}
.bold9{
  font-weight: 900;
}
.center{
  text-align: center;
}
.center3{
  margin: auto 0;
}
.inline-block{
  display: inline-block;
}
.mr_1{
  margin-right: 10px;
}
.mr_2{
  margin-right: 20px;
}
.mr_3{
  margin-right: 30px;
}
.mr_4{
  margin-right: 40px;
}
.mr_5{
  margin-right: 50px;
}
.mr_6{
  margin-right: 60px;
}
.mr_7{
  margin-right: 70px;
}

.mx_1{
  margin: 0 10px ;
}
.mx_2{
  margin: 0 20px ;
}
.mx_3{
  margin: 0 30px ;
}
.mx_4{
  margin: 0 40px ;
}
.mx_5{
  margin: 0 50px ;
}
.mx_6{
  margin: 0 60px ;
}
.mx_7{
  margin: 0 70px ;
}


.my_1{
  margin: 10px 0;
}
.my_2{
  margin: 20px 0;
}
.my_3{
  margin: 30px 0;
}
.my_4{
  margin: 40px 0;
}
.my_5{
  margin: 50px 0;
}
.my_6{
  margin: 60px 0;
}
.my_7{
  margin: 70px 0;
}


.ml_1{
  margin-left: 10px;
}
.ml_2{
  margin-left: 20px;
}
.ml_3{
  margin-left: 30px;
}
.ml_4{
  margin-left: 40px;
}
.ml_5{
  margin-left: 50px;
}
.ml_6{
  margin-left: 60px;
}
.ml_7{
  margin-left: 70px;
}



.mb_1{
  margin-bottom: 10px;
}
.mb_2{
  margin-bottom: 20px;
}
.mb_3{
  margin-bottom: 30px;
}
.mb_4{
  margin-bottom: 40px;
}
.mb_5{
  margin-bottom: 50px;
}
.mb_6{
  margin-bottom: 60px;
}
.mb_7{
  margin-bottom: 70px;
}





.mt_1{
  margin-top: 10px;
}
.mt_15{
  margin-top: 15px;
}
.mt_2{
  margin-top: 20px;
}
.mt_3{
  margin-top: 30px;
}
.mt_4{
  margin-top: 40px;
}
.mt_5{
  margin-top: 50px;
}
.mt_6{
  margin-top: 60px;
}
.mt_7{
  margin-top: 70px;
}

.mt_8{
  margin-top: 80px;
}
.mt_9{
  margin-top: 90px;
}








.pr_1{
  padding-right: 10px;
}
.pr_2{
  padding-right: 20px;
}
.pr_3{
  padding-right: 30px;
}
.pr_4{
  padding-right: 40px;
}
.pr_5{
  padding-right: 50px;
}
.pr_6{
  padding-right: 60px;
}
.pr_7{
  padding-right: 70px;
}



.pl_1{
  padding-left: 10px;
}
.pl_2{
  padding-left: 20px;
}
.pl_3{
  padding-left: 30px;
}
.pl_4{
  padding-left: 40px;
}
.pl_5{
  padding-left: 50px;
}
.pl_6{
  padding-left: 60px;
}
.pl_7{
  padding-left: 70px;
}



.pb_1{
  padding-bottom: 10px;
}
.pb_2{
  padding-bottom: 20px;
}
.pb_3{
  padding-bottom: 30px;
}
.pb_4{
  padding-bottom: 40px;
}
.pb_5{
  padding-bottom: 50px;
}
.pb_6{
  padding-bottom: 60px;
}
.pb_7{
  padding-bottom: 70px;
}



.pt_1{
  padding-top: 10px;
}
.pt_2{
  padding-top: 20px;
}
.pt_3{
  padding-top: 30px;
}
.pt_4{
  padding-top: 40px;
}
.pt_5{
  padding-top: 50px;
}
.pt_6{
  padding-top: 60px;
}
.pt_7{
  padding-top: 70px;
}





.px_1{
  padding: 0 10px ;
}
.px_2{
  padding: 0 20px ;
}
.px_3{
  padding: 0 30px ;
}
.px_4{
  padding: 0 40px ;
}
.px_5{
  padding: 0 50px ;
}
.px_6{
  padding: 0 60px ;
}
.px_7{
  padding: 0 70px ;
}

.py_05{
  padding: 5px 0;
}
.py_1{
  padding: 10px 0;
}
.py_2{
  padding: 20px 0;
}
.py_3{
  padding: 30px 0;
}
.py_4{
  padding: 40px 0;
}
.py_5{
  padding: 50px 0;
}
.py_6{
  padding: 60px 0;
}
.py_7{
  padding: 70px 0;
}

/* 
.sffgghdg{
    font-family: 'Comfortaa', cursive;
    font-family: 'Fredoka', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Poppins', sans-serif;
} */

.bg-none{
    background-color: #fff;
}
.LandingPage{
    margin-top: -65px;
    min-height: 630px;
    background: linear-gradient(146.41deg, rgba(247, 37, 133, 0.09) 10.14%, rgb(255,255,255) 66.6%);
    z-index: 2;
}
.relative.xf{
    min-height: 630px;
}
.LandingImg{
    position: absolute;
    right: 0;
    top: 30px;
    max-width: 790px;
    z-index: 2;
}
.secondStandardWidth{
    max-width: 1050px;
    padding: 0 20px;
    margin: 0 auto;
}
.HeaderOne{
    color: #3A0CA3;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}
.text_shadow{
    text-shadow: 0.7px 0.7px #fff;
}
.headerColor{
    color: #3A0CA3;
}
.centerMe{
    padding-top: 240px;
}
.centerMeServices{
    padding-top: 140px;
}
.containerLander{
    max-width: 450px;
    width: 100%;
    position: relative;
    z-index: 10;
}
.GeneralBookingBtn{
    background-color: #4361EE;
    padding: 11px 20px;
    max-width: 200px;
    width: 100%;
    color: #fff;
    border-radius: 6px;
}
.GeneralBookingBtn.xtra{
    background-color: #F72585;
}
.square1{
    position: absolute;
    top: 80px;
    right: 5.5%;
    max-height: 11.75vw;

}
.square2{
    position: absolute;
    bottom: 0;
    max-height: 11.45vw;
    left: 28vw;
}
.square3{
    position: absolute;
    top: 100px;
    left: 21vw;
    max-height: 7.95vw;

}
.thirdStandardWidth{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.HomeAbout{
    padding: 110px 0;
}
.HomeAbout.deigns{
    padding: 30px 0;
}
.HomeAbout.xdr{
    padding: 110px 0 10px 0; 
}
.blue{
    color: #0081FE;
}
.Header2{
    color: #3A0CA3;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

}
.colsM .divImg{
    padding: 40px 0 20px 0;
}
.Header3{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;

}
.Header40{
    color: #3A0CA3;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

}
.VisionContainer{
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 640px;
    width: 100%;
    margin: auto;
}

.py_15{
    padding: 15px;
}
.visionCenter{
    border-radius: 10px;
    padding: 20px 5px;
    cursor: pointer;
}
.visionCenter:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

}
.square4{
    position: absolute;
    top: 100px;
    left: -9vw;
    max-height: 8vw;
}
.square9{
    position: absolute;
    top: 40px;
    left: 14vw;
    max-height: 11.75vw;
}

.square10{
    position: absolute;
    bottom: 400px;
    right: 0;
    max-height: 11.75vw;
}
.square11{
    position: absolute;
    bottom: 500px;
    right: 9vw;
    max-height: 11.75vw;
}
.square12{
    position: absolute;
    bottom: 400px;
    left: 2vw;
    max-height: 11.75vw;
}

.square13{
    position: absolute;
    top: 0;
    left: 13vw;
    max-height: 11.75vw;
}
.square14{
    position: absolute;
    top: 100px;
    right: 5vw;
    max-height: 11.75vw;
}
.square15{
    position: absolute;
    bottom: 450px;
    right: 15vw;
    max-height: 11.75vw;
}

.square16{
    position: absolute;
    top: -60px;
    right: 25vw;
    max-height: 11.75vw;
}

.square6{
    position: absolute;
    bottom: 40px;
    right: 7vw;
    max-height: 7.95vw;

}

.square7{
    position: absolute;
    bottom: 20px;
    left: 14vw;
    /* max-height: 8vw; */
    
}


.square8{
    position: absolute;
    top: 40px;
    left: 14vw;
    max-height: 7.95vw;

}

.bg_Update{
    background-color: #7593FF;
    padding: 120px 0 100px 0;
}
.TheInfo{
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.red{
    color: #F72585;
}
.borderBottoms{
    border: 1px solid #E4E4E4;
    max-width: 640px;
    width: 100%;
    margin: 50px auto 25px auto;
}
.bg_Services{
    background: linear-gradient(146.41deg, rgba(67, 97, 238, 0) 31.14%, rgba(67, 97, 238, 0.06) 66.6%);
}
.ServicesContainers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px;
    position: relative;
    z-index: 50;
}
.ServicesContainersAbout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}
.ServicesContainersAbout img{
    border-radius: 15px;
}
.colsM{
    border-radius: 10px;
    padding: 25px;
    background-color: #fff;
    cursor: pointer;
}
.colsM:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.borderBottomss{
    border: 1px solid #E4E4E4;
    max-width: 640px;
    width: 100%;
    margin: 35px auto;
}

.bookserViceOption{
    color: #4361EE;
    font-size: 12px;
    font-weight: 500;
}
.PackagesContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.PackageMini{
    border-top: 2px solid #4361EE;
    border-radius: 0 0 6px 6px;
    box-shadow: -0.715157px 4.29094px 10.7274px rgba(7, 15, 52, 0.1);
    z-index: 5;
}
.PackageMini:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
}
.PackageMini.rbk{
    border-top: 2px solid #F72585;
    position: absolute;
    right: -28px;
    width: 100%;
    z-index: 1;
}
.Basicx{
    color: #F72585;
    padding-right: 5px;
}
.Standardx{
    color: #7209B7;
    padding-right: 5px;
}
.Premiumx{
    color: #4361EE;
    padding-right: 5px;
}
.PackageMini.rbk:hover,
.PackageMini.lbk:hover{
    z-index: 6;
}
.PackageMini.lbk{
    border-top: 2px solid #7209B7;
    position: absolute;
    left: -15px;
    width: 100%;
    z-index: 1;
}
.essentialContainer{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 17px;
    background-color: #F4F5FE;
}
.innerSiderContent{
    padding: 25px;
    background-color: #fff;
    border-radius: 0 0 6px 6px;

}
.priceContainer{
    font-size: 40px;
    font-weight: 500;
    padding: 0 3px;
}
.packagesPlanBtn{
    background-color: #4361EE;
    display: block;
    text-align: center;
    padding: 6px;
    color: #fff;
    border-radius: 4.2px;
}
.outerPackageMini{
    padding-top: 30px;
}
.bg_Brownish{
    background-color: #F3F5FE;
}
.feedbackContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.FeedbackContent{
    border: 1px solid #D6DEFF;
    padding: 20px;
    background-color: #F7F9FF;
    border-radius: 8px;
}
.backgroundFooterimg{
    position: absolute;
    top: 0;
}
.NewsLetter{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 20px;
    padding: 50px 40px;
    background-color: #3A0CA3;
    border-radius: 10px;
    box-shadow: 1px 1rem 1rem rgba(0,0,0,.15);
    position: relative;
    z-index: 2;
}
.magerFooter{
    padding: 80px 0;
}

.NewsLetterFormContainer{
    background-color: #fff;
    display: flex;
    padding: 5px;
    border-radius: 4px;
}
.NewsLetterFormContainer button{
    background-color: #0081FE;
    border-radius: 4px;
    padding: 9px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

}

.NewsLetterFormContainer button.success{
    background-color: #52A57E;
    width: 100%;
}

.NewsLetterFormContainer input{
    border: none;
    width: 100%;
    height: 100%;
    max-width: 250px;
}

.centerServices{
    max-width:750px;
    width:100%;
    margin: 0 auto;
}


.gridDisp{
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-radius: 10px;
    padding: 6px;
    background-color: #fff;
}

.QucikServices{
    padding-top: 20px;
    position:relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
}
.displayMoreServices{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
#xd::-webkit-scrollbar {
    width: 0;
    height: 0;
}



.hover_Effect .hover_Effect2{
    display: none;
}
.hover_Effect:hover .hover_Effect2{
    display: block;
}


.hover_Effect2{
    position: absolute;
    z-index: 100000 !important;

}
.hover_Effect{
    position: relative;
    padding-top: 5px;
}
.drop_Down_containers{
    background-color: #fff;
    margin-top: 10px;
    margin-left: 20px;
    width: 250px;
    position: relative;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
}
/* .justify_me *{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: -2px;
    word-break: break-all;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.justify_me{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: -2px;
    word-break: break-all;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.no_justify{
    text-align: initial !important;
} */
.HomeAbout.smPadi{
    padding-top: 20px;
}
.higher{
    position: relative;
    z-index: 1000;
}
@media (max-width: 990px) {
    .LandingPage{
        margin-top: -80px;
        overflow-x: hidden;
    }
    .ServicesContainers.px_5{
        padding: 0;
    }
    .QucikServices{
        min-width: 900px;
    }
    .idli{
        overflow: auto;
        padding: 2px 0 40px 20px;
    }
    .centerMeServices{
        padding-top: 100px;
    }
    
}
@media (max-width:900px) {
    .PackagesContainer.px_3{
        padding: 0 ;
    }
    .serve .relative.xf{
        min-height: 400px;
    }
}
@media (max-width:790px) {
    .px_5.xdc{
        padding: 0;
    }
}

@media (max-width:750px) {
    .ServicesContainers{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 700px) {
    .flex.xdcc{
        display: block;
    }
    .flex.xdcc .flex{
        padding-bottom: 20px;
    }
    .flex.xdcc .flex.pl_5{
        padding-left: 15px;
    }
    .ServicesContainersAbout{
        grid-template-columns: 1fr;
    }
    .ServicesContainersAbout.px_5{
        padding: 0;
    }
    
    .centerMe{
        padding-top: 160px;
    }
    .LandingImg.xdf{
        margin-right: -30vw;
        z-index: -1;
    }
    .LandingPage.ttp{
        background: linear-gradient(146.41deg, rgba(247, 37, 133, 0.09) 10.14%, rgb(255,255,255,.9) 66.6%);
    }
    .TheInfo{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
    .TheInfo .pr_3{
        padding-right: 0;
        text-align: center;
    }
    .bg_Update{
        padding: 80px 0 100px 0;
    }
    .HomeAbout{
        padding: 70px 0;
    }
    .HomeAbout.xdr{
        padding: 70px 0 10px 0; 
    }
    .PackagesContainer{
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
    .PackageMini.rbk{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .PackageMini.lbk{
        position: relative;
        left: 0;
        bottom: 0;
        top: 0;
    }
    .outerPackageMini{
        padding-top: 0;
    }
    .PackageMini.las{
        order: 3;
    }
    .feedbackContainer{
        grid-template-columns: 1fr 1fr;
    }
    .NewsLetter{
        grid-template-columns: 1fr;
        padding: 50px 20px;
    }
    .font_24{
        font-size: 19px;
    }
    .NewsLetterFormContainer button{
        min-width: 140px !important;
    }
    
    .NewsLetterFormContainer input{
        max-width: 76.5%;
    }
    .centerMeServices .flex{
        display: block;
    }
    .centerMeServices .flex .pr_3,
    .centerMeServices .flex .pr_4{
        padding-right: 20px;
        padding-left: 20px;
    }
    .displayMoreServices{
        grid-template-columns: 1fr 1fr;
    }
    
}

@media (max-width: 630px) {
    .VisionContainer{
        grid-template-columns: 1fr 1fr;
    }
    .Header40{
        font-size: 28px;
    }
    .centerMe{
        padding-top: 120px;
    }
    /* .containerLander .pt_1{
        padding-top: 220px;
    } */
    .containerLander .pt_1{
        display: none;
    }
    .containerLander .HeaderOne{
        padding-top: 300px;
    }
}
@media (max-width: 500px) {
    .serve .centerMeServices.relative{
        padding-top: 50px;
    }
    
    .HeaderOne{
        font-size: 30px;
    }
    .Header2{
        font-size: 24px;
    }
    .Header3{
        font-size: 26px;
    }
    .pr_2.xrc{
        padding-right: 0;
    }
    .PackagesContainer{
        grid-template-columns: 1fr;
    }
    .ServicesContainers{
        grid-template-columns: 1fr;
    }
    .centerMeServices .font_28{
        font-size: 20px;
    }
    .centerMeServices .font_16{
        font-size: 14px;
    }
}
@media (max-width: 460px) {
    .feedbackContainer{
        grid-template-columns: 1fr;
    }
    
}
@media (max-width: 400px) {
    .LandingImg{
        margin-right: -70vw;
    }
    .VisionContainer{
        grid-template-columns: 1fr;
    }
    .NewsLetterFormContainer button{
        min-width: 100px !important;
        padding: 6px 12px;
        color: #fff;
        font-size: 10px;
    }
    .NewsLetter{
        padding: 30px 10px;
    }
    .displayMoreServices{
        grid-template-columns: 1fr;
    }
}


































.MyNav{
    padding: 15px;
    border-bottom: 1px solid #E0E4F2;
    position: relative;
    z-index: 100;
}
.firstStandardWidth{
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}
.logo_container{
    max-width: 250px;
    width: 100%;
    /* padding-top: 7px; */
}
.NavLinksContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.NavRequestServicesBtn{
    background-color: #4361EE;
    color: #fff;
    font-weight: 500;
    border-radius: 6px;
    padding: 7px 20px;
    font-size: 14px;
}
.NavLink{
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
}
.NavLink.xr{
    padding: 7px 25px 7px 50px;
    color: #4361EE !important;
}

.modalBodyy{
    max-width: 500px;
    width: 100%;
    margin: 0 auto 10px auto ;
}
.activities{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}
.modalBodyy .activities span{
    height: 2px;
    background-color: #D5D5D5;
    border-radius: 20px;
    max-width: 80px;
    width: 100%;
    display: block;
    cursor: pointer;
}
.modalBodyy .activities.xrc span{
    max-width: 100%;
}
.modalBodyy .activities span.active{
    background-color: #4361EE;
}
.mdalContent{
    margin: 0 auto 0 auto;
    /* margin: 50px auto 90px; */
    max-width: 310px;
    width: 100%;
    /* background-color: #4361EE; */
}
.DForm input{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 42px;
    padding: 0 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 15px;
}
.DForm input.xdForm,
.DForm textarea.xdForm{
    border: 1px solid #DC3545;
}
.dispDisd.xdForm{
    border: 1px solid #DC3545;
}
.ReactFlagsSelect-module_label__27pw9 {
    display: none;
}

.dispDisd{
    border: 1px solid #D7D7DB;
    display: flex;
    border-radius: 8px;
}

.dispDisd input{
    width: 100%;
    height: 42px;
    font-size: 15px;
    border: none;
    border-radius: 8px;
}
.widthSelected{
    width: 100px;
}
.min_ht{
    min-height: 450px;
}
.next_btnForm{
    background-color: #fff;
    color: #4361EE;
    font-weight: 600;
}
.next_btnFormContainer{
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.prev_btnFormContainer{
    position: absolute;
    bottom: 10px;
    left: 20px;
}

.selectServicesContent select{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 42px;
    padding: 0 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 15px;
}
.selectServicesContent select.xdForm{
    border: 1px solid #DC3545 !important;
}
.DForm textarea{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 150px;
    padding: 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
    resize: none;
}
.pb_05{
    padding-bottom: 5px;
}
.BreadCrumbs{
    display: none;
}

@media (max-width:1000px) {
    .flex.xdccx{
        display: block !important;
    }
    .flex.xdccx .flex{
        padding-bottom: 20px;
    }
    .flex.xdccx .flex.pl_5{
        padding-left: 15px;
    }
}
@media (max-width: 960px) {
    .logo_container{
        max-width: 180px;
    }
}
@media (max-width: 890px) {
    .BreadCrumbs{
        display: block;
        padding: 7px 15px;
        font-weight: 500;
        font-size: 14px;
    }
    .NavLink{
        display: none;
    }
    .NavLink.xr{
        display: block;
        padding: 7px 25px;
    }
    .NavLinksContainer{
        justify-content: end;
    }
    
}

@media (max-width:500px) {
    .NavLinksContainer{
        max-width: 70px;
        margin-left: auto;
    }
    .NavLink.xr{
        display: none;
    }
    .NavLinksContainer .NavRequestServicesBtn{
        display: none;
    }
    .MyNav{
        padding: 2px 0 2px 12px;
    }
}












.footer{
    padding: 0 0 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #cbdaec;
}
.flexFooter{
    display: flex;
    justify-content: space-between;
}

@media (max-width:500px) {
    .footer{
        grid-template-columns: 1fr;
    }
    .flexFooter .font_14{
        font-size: 12px;
    }
    .flexFooter{
        padding-bottom: 35px;
    }
    .zaFooter .left_auto{
        margin-left: initial;
    }
    .zaFooter .py_3.flex{
        display: block;
        text-align: center;
    }
}



















.loginFormBg{
    background-color: #EFF0F4;
    height: 94vh;
}
.loginFormBg2{
    background-color: #EFF0F4;
}
.paddForm{
    padding: 90px 0;
}
.loginContainerBox3{
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    margin: auto;
    padding: 40px;
    border-radius: 7px;
}
.loginContainerBox3 input,
.registerContent input{
    margin: 15px 0;
    padding: 9px 10px;
    display: block;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #ccc7c7;
}
.loginContainerBox3 input.xb,
.registerContent input.xb{
    border: none;
    margin: 0;

}
.loginContainerBox3 button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 9px;
}
.passwordFormContainer{
    display: flex;
    margin: 15px 0;
    /* padding: 9px 10px; */
    border-radius: 4px;
    border: 1px solid #ccc7c7;
}
.divideSetiingsInfo{
    display: grid;
    grid-template-columns: 5fr 1fr;
}
.sight{
    padding-top: 9px;
    padding-right: 5px;
}

.DigitalMArketingLander{
    background: linear-gradient(rgb(1,30,96,.5) , rgb(1,30,96,.8)),url(/static/media/mentorship.adc839cc.jpg);
    min-height: 400px;
    padding: 50px 0 120px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.digMarktContainer{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 30px;
}
.digitsContent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.DigitList{
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #f1f1f1;
}
.DigitList:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.DigitalMarketing{
    border-top: 1px solid #f1f1f1;
    padding: 60px;
}
.DigitalMarketingContainer{
    display: grid;
    grid-template-columns: 3fr 1fr;
    background-color: rgb(67,97,238,.1);
    border-radius: 10px;
    padding: 70px;

}
.DigitalMarketingContainer .font_38,.xlx{
    max-width: 700px;
}
.digMarktContents{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}
.shads{
    border-radius: 15px;
    border: 1px solid #f1f1f1;
    position: relative;
    z-index: 3;
    margin-bottom: 80px;
}
.shads .font_14{
    padding: 40px 40px 50px 40px;
}
.digitsBookBtn{
    height: 65px;
    width: 65px;
    border-radius: 100%;
    background-color: #3491E8;
    color: white;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    /* padding-top: 21px; */
    position: absolute;
    bottom: -32px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 12;
}
.shadowMe{
    height: 65px;
    width: 65px;
    border-radius: 100%;
    position: absolute;
    bottom: -32px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
}
.faqsContentContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}
.coldsf{
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 15px;
    border: 1px solid #f1f1f1;
    border-bottom: 4px solid #3491E8;
    border-radius: 10px 10px 0 0;
    padding: 20px;
}
.coldsf:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.tutDtal{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 40px 20px;
}
.round{
    border-radius: 5px !important;
    border: 0.5px solid #f1f1f1;
}
.classCpontent{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 30px;
    row-gap: 30px;
    padding: 0 20px;
}
.mainClasscontent{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 30px;
    margin: 40px 0;
    
}
.theDividersGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.classDuration{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 30px;
    margin: 40px 0;
}
.mainClasscontent ul{
    list-style-image: url("https://img.icons8.com/external-linear-outline-icons-papa-vector/18/external-Right-Double-Arrow-interface-linear-outline-icons-papa-vector.png");
}
.mainClasscontent ul li{
    font-size: 14px;
}
.enrolTutorialBtn{
    background-color: #4361EE;
    padding: 6px 20px ;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border-radius: 3px;
}
.xmxs .modal-header{
    border-bottom: none;
}
.DForm .passwordFormContainer input {
    border: none;
    /* border-radius: 0; */
    margin-bottom: 0;
}
.DForm .passwordFormContainer.xdForm{
    border: 1px solid #DC3545 !important;
}
.certificate_bg{
    background-color: #f1f1f1;
    padding: 40px 20px;
    min-height: 100vh;
}
.certificate{
    background-color: #fff;
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;
}
.checkOrderly{
    padding: 0 15px;
}
.checkOrderly img {
    width: 100%;
}
@media (max-width: 800px) {
    .DigitalMarketing{
        padding: 20px;
    }
    .classCpontent{
        grid-template-columns:1fr;
    }
    .tutDtal{
        grid-template-columns:1fr;
    }
    .checkOrderly{
        padding: 0;
    }
    .checkOrderly img {
        width: auto;
    }
    .tutDtal .p_2{
        order: 2;
    }
    .checkOrderly{
        order: 1;
        padding-bottom: 20px;
    }
}
@media (max-width:700px) {
    .digMarktContainer{
        grid-template-columns: 1fr;
    }
}
@media (max-width: 650px) {
    .DigitalMarketingContainer{
        grid-template-columns: 1fr;
        padding: 20px;
        grid-gap: 40px;
    }
    .DigitalMarketing{
        padding: 10px;
    }
    .font_32{
        font-size: 24px;
    }
    .shads .font_14{
        padding: 30px 30px 50px 30px;
    }
}

@media (max-width:500px) {
    .paddForm{
        padding: 50px 20px;
    }
    .loginContainerBox3{
        padding: 40px 15px;
    }
    .divideSetiingsInfo{
        grid-template-columns: 4fr 2fr;
    }
    .font_38{
        font-size: 25px;
    }
    .digMarktContents{
        grid-template-columns: 1fr;
    }
    .theDividersGrid{
        grid-template-columns: 1fr;
    }
    .mainClasscontent{
        padding: 15px;
        margin: 15px 0;
        
    }
    .classDuration{
        padding: 20px;
        margin: 20px 0;
    }
}
@media (max-width:450px) {
    .digitsContent{
        grid-template-columns: 1fr;
    }
}










.text_copy_synthax{
    padding-top: 5px;
    text-align: right;
}
/*  text muted 6C757D */
.navSideBar{
    position: fixed;
    width: 100px;
    height: 100%;
    /* background-color: aqua;     */
}
.sideBarIconcontainer{
    position: absolute;
    height: 500px;
    width: 70px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #4361EE;
    border-radius: 50px;
}
/* .bg_blur{
    background-color: #f1f1f1;
    min-height: 100vh;
} */
.mainContent{
    background-color: #fff;
    margin: 0 10px 10px 50px;
    border-radius: 15px;
    min-height: 97vh;
    padding-left: 50px;
}

.iconsConts{
    padding-top: 15px;
    text-align: center;
    margin-top: 25px;
}
.iconsConts div{
    margin-bottom: 25px;
}
.dashNavs{
    display: flex;
    padding: 15px 35px 15px 15px;
    border-radius: 10px;
    margin-right: 10px;
}

.iconser{
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.possitionIconizer{
    padding: 10px 0 0 10px ;
}
.dashNavLinks{
    padding: 14px 10px 0 10px ;
}
.mainContentDashboard{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 40px;
    padding: 20px 50px 20px 20px;
}
.plansContainerInfo{
    padding: 15px;
    border-radius: 20px;
}
.spand{
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 2px;
}
.spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #ddd9d9;
}
.subscribe_btn{
    background-color: #f1f1f1;
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}

.subscribe_btns{
    background-color: rgb(207,82,113,.1);
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}
.recolor{
    color: rgb(207,82,113);
}

.plansContainerInfos{
    padding: 5px;
    border-radius: 20px;
}
.plansContainerInfos .p-2{
    border-radius: 15px;
    padding: 9px;
    /* border: 1px solid #f1f1f1; */
}
.bootDesign{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 40px;
    padding: 10px 50px 20px 20px;
}
.bootContent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.active .plansContainerInfo .spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #3491E8;
}
.active .subscribe_btn{
    background-color: rgb(52,145,232,.2);
}
.active .subscribe_btn .text-muted{
    color: rgb(52,145,232) !important;
}
.magazineDashboardContainer{
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-gap: 15px;
    margin-top: 10px;
    padding: 5px;
}
.ClassicItemsSales img{
    height: 118px;
    width: 100%;
    border-radius: 10px;
}
.eventDashboardContainer{
    display: grid;
    grid-template-columns: 2fr 8fr;
    grid-gap: 10px;
    margin-top: 10px;
    /* padding: 5px; */
}


.mainContentDashboardsubs{
    padding: 20px 50px 20px 20px;
}
.mainContentDashboardsubsContainer{
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: 40px;
}

.profileImageContainer img{
    border-radius: 10px;
}
.profileImageContainer{
    position: relative;
}
.padProfilexds{
    padding-top: 20px;
}
.profileSkills div{
    font-size: 14px;
}
.profilePhotosContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.profileVideoContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}
.profileRemarkContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}
.PVContainer{
    border-radius: 10px;
    padding: 20px;
}
.DashboardTicketsContainer{
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: 30px;
}
.tickets{
    padding-left: 10px;
    border-left: 5px solid #4361EE;
    
}
.tickets.rbk{
    border-left: 5px solid #F72585;
}
.colrbk{
    color: #F72585;
}
.collbk{
    color: #7209B7;
}
.tickets.lbk{
    border-left: 5px solid #7209B7;
}
.ticketsHover{
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.ticketsHover:hover{
    border: 1px solid #f1f1f1;
}
.projectDashContainers{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 13px 15px 13px 10px;
    max-width: 350px;
    width: 100%;
    display: inline-block;
}
.projectDashContainers select{
    width: 100%;
    border: none;
}
.progressContentContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 20px;
    margin: 30px 0;
}
.progressBarContainer{
    height: 4px;
    border-radius: 20px;
    background-color: #f1f1f1;
    margin: 15px 0;
}
.progressBar{
    background-color: #4361EE;
    border-radius: 20px;
    height: 100%;
}
.copyIt input{
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    /* max-width: 350px; */
    width: 90%;
}
.profileUserContainer{
    border-left: 5px solid #3491E8;
    padding: 40px;
    border-radius: 0 80px 80px 0;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    margin-top: 40px;

}
.innerUserProfile{
    border-radius: 0 50px 50px 0;
    padding: 20px;
}
.divideProfileInfo{
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 20px;
}

.requestOuterContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 6px;
    margin-bottom: 15px;
}
.requestInnerContainer{
    border-radius: 8px;
}
.reqGriders{
    display: grid;
    grid-template-columns: 80% 20%;
}
.selectedReqContainer{
    border-top: 1px solid #f1f1f1;
    padding: 2px 30px;
}
.requestInnerContainer:hover{
    background-color: #fcfcfc;
}
.padmeReq{
    padding: 10px 0 10px 30px;
}
.alignCenter{
    align-items: center;
    /* background-color: rgb(52,145,232,0.1); */
    border-radius: 0 45px 45px 0;

}
.alignCenter div{
    color: #3491E8;
}

.innerUserProfile input{
    border: none;
    border-bottom: 1px solid #f1f1f1;
    width: 90%;
    height: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
}
.SettingsSubmitBtn{
    color: #fff;
    background: #3491E8;
    border-radius: 10px;
    padding: 8px 25px;
    font-weight: 600;
    font-size: 15px;

}

.bread_crumbs #xd{
    overflow-y: auto;
    height: 95vh;
}
.bread_crumbs{
    /* position: fixed; */
    width: 100%;
    height: 100vh;
    /* background-color: rgba(0,0,0,.6); */
    z-index: 500;
}
.inner_bread_Crumbs{
    padding: 0 20px 0 0;
    
}

.drop_down_container{
    background-color: #040D36;
    padding: 50px 0 0 0;
    -webkit-animation: dropDown 0.3s forwards ;
            animation: dropDown 0.3s forwards ;
    position: relative;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin-left: auto;
}
.navListItems a,.navListItems samp{
    color: #fff;
    font-size: 15px;
    font-family: Font600;
}
.navListItems .xdd.center{
    padding: 15px 0;
}

.flex.xc{
    align-items: center;
    padding-right: 3px;
}
.left_auto.xcd{
    padding-bottom: 3px;
}
.priceContainer_Displayer{
    margin: 15px 0;
    font-weight: 600;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
}
.primax{
    /* padding-right: 5px; */
    font-size: 11px;
}
.orderSummarY .selectedReqContainer{
    padding: 2px 10px;

}
.orderSummarY .padmeReq{
    padding: 10px 0 10px 10px;
}
.bg-muted{
    background-color: #6C757D;
}
.selectorSm{
    width: 13px;
    height: 13px;
    border: 1px solid #6C757D;
    border-radius: 3px;
    cursor: pointer;
}
.selectorSm.bg-primary{
    border: 1px solid #3B71CA !important;
}
.EmailSetUpContainer{
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #a5acb2;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}
@media (max-width:800px) {
    .DashboardTicketsContainer{
        grid-template-columns: 1fr;
    }
    .orderSummarY{
        border-top: 2px solid #f1f1f1;
        padding-top: 20px;
    }
}
@media (max-width: 788px) {
    .dashNavLinks{
        display: none;
    }
    .dashNavs{
        padding: 15px 0 15px 15px;
        margin-right: 0;
    }
}
@media (max-width: 700px) {
    .alignCenter .w-100{
        font-size: 9px;
        padding-right: 5px;
    }
}
@media (max-width:500px) {
    .selectedReqContainer{
        padding: 2px 10px;
    
    }
    .padmeReq{
        padding: 10px 0 10px 10px;
    }
    .priceContainer_Displayer .xc{
        font-size: 14px;
    }
    .priceContainer_Displayer .xc.font_12{
        font-size: 11px;
    }
    
    .priceContainer_Displayer button{
        font-size: 12px !important;
    }
    .navSideBar{
        display: none;
    }
    .mainContent{
        margin: 0 10px 10px 10px;
        padding-left: 0;
    }
    .mainContentDashboardsubs{
        padding: 20px 15px;
    }
    .profileUserContainer{
        padding: 20px;
    }
    .requestOuterContainer .padmeReq .font_14{
        font-size: 12px;
    }
    .requestOuterContainer .padmeReq .font_12{
        font-size: 10px;
    }
    .profileUserContainer .bold8{
        font-size: 11px;
    }
}















