/* 
.sffgghdg{
    font-family: 'Comfortaa', cursive;
    font-family: 'Fredoka', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Poppins', sans-serif;
} */

.bg-none{
    background-color: #fff;
}
.LandingPage{
    margin-top: -65px;
    min-height: 630px;
    background: linear-gradient(146.41deg, rgba(247, 37, 133, 0.09) 10.14%, rgb(255,255,255) 66.6%);
    z-index: 2;
}
.relative.xf{
    min-height: 630px;
}
.LandingImg{
    position: absolute;
    right: 0;
    top: 30px;
    max-width: 790px;
    z-index: 2;
}
.secondStandardWidth{
    max-width: 1050px;
    padding: 0 20px;
    margin: 0 auto;
}
.HeaderOne{
    color: #3A0CA3;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}
.text_shadow{
    text-shadow: 0.7px 0.7px #fff;
}
.headerColor{
    color: #3A0CA3;
}
.centerMe{
    padding-top: 240px;
}
.centerMeServices{
    padding-top: 140px;
}
.containerLander{
    max-width: 450px;
    width: 100%;
    position: relative;
    z-index: 10;
}
.GeneralBookingBtn{
    background-color: #4361EE;
    padding: 11px 20px;
    max-width: 200px;
    width: 100%;
    color: #fff;
    border-radius: 6px;
}
.GeneralBookingBtn.xtra{
    background-color: #F72585;
}
.square1{
    position: absolute;
    top: 80px;
    right: 5.5%;
    max-height: 11.75vw;

}
.square2{
    position: absolute;
    bottom: 0;
    max-height: 11.45vw;
    left: 28vw;
}
.square3{
    position: absolute;
    top: 100px;
    left: 21vw;
    max-height: 7.95vw;

}
.thirdStandardWidth{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.HomeAbout{
    padding: 110px 0;
}
.HomeAbout.deigns{
    padding: 30px 0;
}
.HomeAbout.xdr{
    padding: 110px 0 10px 0; 
}
.blue{
    color: #0081FE;
}
.Header2{
    color: #3A0CA3;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

}
.colsM .divImg{
    padding: 40px 0 20px 0;
}
.Header3{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;

}
.Header40{
    color: #3A0CA3;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;

}
.VisionContainer{
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 640px;
    width: 100%;
    margin: auto;
}

.py_15{
    padding: 15px;
}
.visionCenter{
    border-radius: 10px;
    padding: 20px 5px;
    cursor: pointer;
}
.visionCenter:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

}
.square4{
    position: absolute;
    top: 100px;
    left: -9vw;
    max-height: 8vw;
}
.square9{
    position: absolute;
    top: 40px;
    left: 14vw;
    max-height: 11.75vw;
}

.square10{
    position: absolute;
    bottom: 400px;
    right: 0;
    max-height: 11.75vw;
}
.square11{
    position: absolute;
    bottom: 500px;
    right: 9vw;
    max-height: 11.75vw;
}
.square12{
    position: absolute;
    bottom: 400px;
    left: 2vw;
    max-height: 11.75vw;
}

.square13{
    position: absolute;
    top: 0;
    left: 13vw;
    max-height: 11.75vw;
}
.square14{
    position: absolute;
    top: 100px;
    right: 5vw;
    max-height: 11.75vw;
}
.square15{
    position: absolute;
    bottom: 450px;
    right: 15vw;
    max-height: 11.75vw;
}

.square16{
    position: absolute;
    top: -60px;
    right: 25vw;
    max-height: 11.75vw;
}

.square6{
    position: absolute;
    bottom: 40px;
    right: 7vw;
    max-height: 7.95vw;

}

.square7{
    position: absolute;
    bottom: 20px;
    left: 14vw;
    /* max-height: 8vw; */
    
}


.square8{
    position: absolute;
    top: 40px;
    left: 14vw;
    max-height: 7.95vw;

}

.bg_Update{
    background-color: #7593FF;
    padding: 120px 0 100px 0;
}
.TheInfo{
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.red{
    color: #F72585;
}
.borderBottoms{
    border: 1px solid #E4E4E4;
    max-width: 640px;
    width: 100%;
    margin: 50px auto 25px auto;
}
.bg_Services{
    background: linear-gradient(146.41deg, rgba(67, 97, 238, 0) 31.14%, rgba(67, 97, 238, 0.06) 66.6%);
}
.ServicesContainers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px;
    position: relative;
    z-index: 50;
}
.ServicesContainersAbout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}
.ServicesContainersAbout img{
    border-radius: 15px;
}
.colsM{
    border-radius: 10px;
    padding: 25px;
    background-color: #fff;
    cursor: pointer;
}
.colsM:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.borderBottomss{
    border: 1px solid #E4E4E4;
    max-width: 640px;
    width: 100%;
    margin: 35px auto;
}

.bookserViceOption{
    color: #4361EE;
    font-size: 12px;
    font-weight: 500;
}
.PackagesContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.PackageMini{
    border-top: 2px solid #4361EE;
    border-radius: 0 0 6px 6px;
    box-shadow: -0.715157px 4.29094px 10.7274px rgba(7, 15, 52, 0.1);
    z-index: 5;
}
.PackageMini:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
}
.PackageMini.rbk{
    border-top: 2px solid #F72585;
    position: absolute;
    right: -28px;
    width: 100%;
    z-index: 1;
}
.Basicx{
    color: #F72585;
    padding-right: 5px;
}
.Standardx{
    color: #7209B7;
    padding-right: 5px;
}
.Premiumx{
    color: #4361EE;
    padding-right: 5px;
}
.PackageMini.rbk:hover,
.PackageMini.lbk:hover{
    z-index: 6;
}
.PackageMini.lbk{
    border-top: 2px solid #7209B7;
    position: absolute;
    left: -15px;
    width: 100%;
    z-index: 1;
}
.essentialContainer{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 17px;
    background-color: #F4F5FE;
}
.innerSiderContent{
    padding: 25px;
    background-color: #fff;
    border-radius: 0 0 6px 6px;

}
.priceContainer{
    font-size: 40px;
    font-weight: 500;
    padding: 0 3px;
}
.packagesPlanBtn{
    background-color: #4361EE;
    display: block;
    text-align: center;
    padding: 6px;
    color: #fff;
    border-radius: 4.2px;
}
.outerPackageMini{
    padding-top: 30px;
}
.bg_Brownish{
    background-color: #F3F5FE;
}
.feedbackContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.FeedbackContent{
    border: 1px solid #D6DEFF;
    padding: 20px;
    background-color: #F7F9FF;
    border-radius: 8px;
}
.backgroundFooterimg{
    position: absolute;
    top: 0;
}
.NewsLetter{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 20px;
    padding: 50px 40px;
    background-color: #3A0CA3;
    border-radius: 10px;
    box-shadow: 1px 1rem 1rem rgba(0,0,0,.15);
    position: relative;
    z-index: 2;
}
.magerFooter{
    padding: 80px 0;
}

.NewsLetterFormContainer{
    background-color: #fff;
    display: flex;
    padding: 5px;
    border-radius: 4px;
}
.NewsLetterFormContainer button{
    background-color: #0081FE;
    border-radius: 4px;
    padding: 9px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

}

.NewsLetterFormContainer button.success{
    background-color: #52A57E;
    width: 100%;
}

.NewsLetterFormContainer input{
    border: none;
    width: 100%;
    height: 100%;
    max-width: 250px;
}

.centerServices{
    max-width:750px;
    width:100%;
    margin: 0 auto;
}


.gridDisp{
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-radius: 10px;
    padding: 6px;
    background-color: #fff;
}

.QucikServices{
    padding-top: 20px;
    position:relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
}
.displayMoreServices{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
#xd::-webkit-scrollbar {
    width: 0;
    height: 0;
}



.hover_Effect .hover_Effect2{
    display: none;
}
.hover_Effect:hover .hover_Effect2{
    display: block;
}


.hover_Effect2{
    position: absolute;
    z-index: 100000 !important;

}
.hover_Effect{
    position: relative;
    padding-top: 5px;
}
.drop_Down_containers{
    background-color: #fff;
    margin-top: 10px;
    margin-left: 20px;
    width: 250px;
    position: relative;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
}
/* .justify_me *{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: -2px;
    word-break: break-all;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.justify_me{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: -2px;
    word-break: break-all;
    hyphens: auto;
    -webkit-hyphens: auto;
}
.no_justify{
    text-align: initial !important;
} */
.HomeAbout.smPadi{
    padding-top: 20px;
}
.higher{
    position: relative;
    z-index: 1000;
}
@media (max-width: 990px) {
    .LandingPage{
        margin-top: -80px;
        overflow-x: hidden;
    }
    .ServicesContainers.px_5{
        padding: 0;
    }
    .QucikServices{
        min-width: 900px;
    }
    .idli{
        overflow: auto;
        padding: 2px 0 40px 20px;
    }
    .centerMeServices{
        padding-top: 100px;
    }
    
}
@media (max-width:900px) {
    .PackagesContainer.px_3{
        padding: 0 ;
    }
    .serve .relative.xf{
        min-height: 400px;
    }
}
@media (max-width:790px) {
    .px_5.xdc{
        padding: 0;
    }
}

@media (max-width:750px) {
    .ServicesContainers{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 700px) {
    .flex.xdcc{
        display: block;
    }
    .flex.xdcc .flex{
        padding-bottom: 20px;
    }
    .flex.xdcc .flex.pl_5{
        padding-left: 15px;
    }
    .ServicesContainersAbout{
        grid-template-columns: 1fr;
    }
    .ServicesContainersAbout.px_5{
        padding: 0;
    }
    
    .centerMe{
        padding-top: 160px;
    }
    .LandingImg.xdf{
        margin-right: -30vw;
        z-index: -1;
    }
    .LandingPage.ttp{
        background: linear-gradient(146.41deg, rgba(247, 37, 133, 0.09) 10.14%, rgb(255,255,255,.9) 66.6%);
    }
    .TheInfo{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
    .TheInfo .pr_3{
        padding-right: 0;
        text-align: center;
    }
    .bg_Update{
        padding: 80px 0 100px 0;
    }
    .HomeAbout{
        padding: 70px 0;
    }
    .HomeAbout.xdr{
        padding: 70px 0 10px 0; 
    }
    .PackagesContainer{
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
    .PackageMini.rbk{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .PackageMini.lbk{
        position: relative;
        left: 0;
        bottom: 0;
        top: 0;
    }
    .outerPackageMini{
        padding-top: 0;
    }
    .PackageMini.las{
        order: 3;
    }
    .feedbackContainer{
        grid-template-columns: 1fr 1fr;
    }
    .NewsLetter{
        grid-template-columns: 1fr;
        padding: 50px 20px;
    }
    .font_24{
        font-size: 19px;
    }
    .NewsLetterFormContainer button{
        min-width: 140px !important;
    }
    
    .NewsLetterFormContainer input{
        max-width: 76.5%;
    }
    .centerMeServices .flex{
        display: block;
    }
    .centerMeServices .flex .pr_3,
    .centerMeServices .flex .pr_4{
        padding-right: 20px;
        padding-left: 20px;
    }
    .displayMoreServices{
        grid-template-columns: 1fr 1fr;
    }
    
}

@media (max-width: 630px) {
    .VisionContainer{
        grid-template-columns: 1fr 1fr;
    }
    .Header40{
        font-size: 28px;
    }
    .centerMe{
        padding-top: 120px;
    }
    /* .containerLander .pt_1{
        padding-top: 220px;
    } */
    .containerLander .pt_1{
        display: none;
    }
    .containerLander .HeaderOne{
        padding-top: 300px;
    }
}
@media (max-width: 500px) {
    .serve .centerMeServices.relative{
        padding-top: 50px;
    }
    
    .HeaderOne{
        font-size: 30px;
    }
    .Header2{
        font-size: 24px;
    }
    .Header3{
        font-size: 26px;
    }
    .pr_2.xrc{
        padding-right: 0;
    }
    .PackagesContainer{
        grid-template-columns: 1fr;
    }
    .ServicesContainers{
        grid-template-columns: 1fr;
    }
    .centerMeServices .font_28{
        font-size: 20px;
    }
    .centerMeServices .font_16{
        font-size: 14px;
    }
}
@media (max-width: 460px) {
    .feedbackContainer{
        grid-template-columns: 1fr;
    }
    
}
@media (max-width: 400px) {
    .LandingImg{
        margin-right: -70vw;
    }
    .VisionContainer{
        grid-template-columns: 1fr;
    }
    .NewsLetterFormContainer button{
        min-width: 100px !important;
        padding: 6px 12px;
        color: #fff;
        font-size: 10px;
    }
    .NewsLetter{
        padding: 30px 10px;
    }
    .displayMoreServices{
        grid-template-columns: 1fr;
    }
}

































