.loginFormBg{
    background-color: #EFF0F4;
    height: 94vh;
}
.loginFormBg2{
    background-color: #EFF0F4;
}
.paddForm{
    padding: 90px 0;
}
.loginContainerBox3{
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    margin: auto;
    padding: 40px;
    border-radius: 7px;
}
.loginContainerBox3 input,
.registerContent input{
    margin: 15px 0;
    padding: 9px 10px;
    display: block;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #ccc7c7;
}
.loginContainerBox3 input.xb,
.registerContent input.xb{
    border: none;
    margin: 0;

}
.loginContainerBox3 button{
    background-color: #3491E8;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 9px;
}
.passwordFormContainer{
    display: flex;
    margin: 15px 0;
    /* padding: 9px 10px; */
    border-radius: 4px;
    border: 1px solid #ccc7c7;
}
.divideSetiingsInfo{
    display: grid;
    grid-template-columns: 5fr 1fr;
}
.sight{
    padding-top: 9px;
    padding-right: 5px;
}

.DigitalMArketingLander{
    background: linear-gradient(rgb(1,30,96,.5) , rgb(1,30,96,.8)),url(mentorship.jpg);
    min-height: 400px;
    padding: 50px 0 120px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.digMarktContainer{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 30px;
}
.digitsContent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.DigitList{
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #f1f1f1;
}
.DigitList:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.DigitalMarketing{
    border-top: 1px solid #f1f1f1;
    padding: 60px;
}
.DigitalMarketingContainer{
    display: grid;
    grid-template-columns: 3fr 1fr;
    background-color: rgb(67,97,238,.1);
    border-radius: 10px;
    padding: 70px;

}
.DigitalMarketingContainer .font_38,.xlx{
    max-width: 700px;
}
.digMarktContents{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}
.shads{
    border-radius: 15px;
    border: 1px solid #f1f1f1;
    position: relative;
    z-index: 3;
    margin-bottom: 80px;
}
.shads .font_14{
    padding: 40px 40px 50px 40px;
}
.digitsBookBtn{
    height: 65px;
    width: 65px;
    border-radius: 100%;
    background-color: #3491E8;
    color: white;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    /* padding-top: 21px; */
    position: absolute;
    bottom: -32px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 12;
}
.shadowMe{
    height: 65px;
    width: 65px;
    border-radius: 100%;
    position: absolute;
    bottom: -32px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
}
.faqsContentContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}
.coldsf{
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 15px;
    border: 1px solid #f1f1f1;
    border-bottom: 4px solid #3491E8;
    border-radius: 10px 10px 0 0;
    padding: 20px;
}
.coldsf:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.tutDtal{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 40px 20px;
}
.round{
    border-radius: 5px !important;
    border: 0.5px solid #f1f1f1;
}
.classCpontent{
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    padding: 0 20px;
}
.mainClasscontent{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 30px;
    margin: 40px 0;
    
}
.theDividersGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.classDuration{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 30px;
    margin: 40px 0;
}
.mainClasscontent ul{
    list-style-image: url("https://img.icons8.com/external-linear-outline-icons-papa-vector/18/external-Right-Double-Arrow-interface-linear-outline-icons-papa-vector.png");
}
.mainClasscontent ul li{
    font-size: 14px;
}
.enrolTutorialBtn{
    background-color: #4361EE;
    padding: 6px 20px ;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border-radius: 3px;
}
.xmxs .modal-header{
    border-bottom: none;
}
.DForm .passwordFormContainer input {
    border: none;
    /* border-radius: 0; */
    margin-bottom: 0;
}
.DForm .passwordFormContainer.xdForm{
    border: 1px solid #DC3545 !important;
}
.certificate_bg{
    background-color: #f1f1f1;
    padding: 40px 20px;
    min-height: 100vh;
}
.certificate{
    background-color: #fff;
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;
}
.checkOrderly{
    padding: 0 15px;
}
.checkOrderly img {
    width: 100%;
}
@media (max-width: 800px) {
    .DigitalMarketing{
        padding: 20px;
    }
    .classCpontent{
        grid-template-columns:1fr;
    }
    .tutDtal{
        grid-template-columns:1fr;
    }
    .checkOrderly{
        padding: 0;
    }
    .checkOrderly img {
        width: auto;
    }
    .tutDtal .p_2{
        order: 2;
    }
    .checkOrderly{
        order: 1;
        padding-bottom: 20px;
    }
}
@media (max-width:700px) {
    .digMarktContainer{
        grid-template-columns: 1fr;
    }
}
@media (max-width: 650px) {
    .DigitalMarketingContainer{
        grid-template-columns: 1fr;
        padding: 20px;
        grid-gap: 40px;
    }
    .DigitalMarketing{
        padding: 10px;
    }
    .font_32{
        font-size: 24px;
    }
    .shads .font_14{
        padding: 30px 30px 50px 30px;
    }
}

@media (max-width:500px) {
    .paddForm{
        padding: 50px 20px;
    }
    .loginContainerBox3{
        padding: 40px 15px;
    }
    .divideSetiingsInfo{
        grid-template-columns: 4fr 2fr;
    }
    .font_38{
        font-size: 25px;
    }
    .digMarktContents{
        grid-template-columns: 1fr;
    }
    .theDividersGrid{
        grid-template-columns: 1fr;
    }
    .mainClasscontent{
        padding: 15px;
        margin: 15px 0;
        
    }
    .classDuration{
        padding: 20px;
        margin: 20px 0;
    }
}
@media (max-width:450px) {
    .digitsContent{
        grid-template-columns: 1fr;
    }
}









