.MyNav{
    padding: 15px;
    border-bottom: 1px solid #E0E4F2;
    position: relative;
    z-index: 100;
}
.firstStandardWidth{
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}
.logo_container{
    max-width: 250px;
    width: 100%;
    /* padding-top: 7px; */
}
.NavLinksContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.NavRequestServicesBtn{
    background-color: #4361EE;
    color: #fff;
    font-weight: 500;
    border-radius: 6px;
    padding: 7px 20px;
    font-size: 14px;
}
.NavLink{
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
}
.NavLink.xr{
    padding: 7px 25px 7px 50px;
    color: #4361EE !important;
}

.modalBodyy{
    max-width: 500px;
    width: 100%;
    margin: 0 auto 10px auto ;
}
.activities{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}
.modalBodyy .activities span{
    height: 2px;
    background-color: #D5D5D5;
    border-radius: 20px;
    max-width: 80px;
    width: 100%;
    display: block;
    cursor: pointer;
}
.modalBodyy .activities.xrc span{
    max-width: 100%;
}
.modalBodyy .activities span.active{
    background-color: #4361EE;
}
.mdalContent{
    margin: 0 auto 0 auto;
    /* margin: 50px auto 90px; */
    max-width: 310px;
    width: 100%;
    /* background-color: #4361EE; */
}
.DForm input{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 42px;
    padding: 0 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 15px;
}
.DForm input.xdForm,
.DForm textarea.xdForm{
    border: 1px solid #DC3545;
}
.dispDisd.xdForm{
    border: 1px solid #DC3545;
}
.ReactFlagsSelect-module_label__27pw9 {
    display: none;
}

.dispDisd{
    border: 1px solid #D7D7DB;
    display: flex;
    border-radius: 8px;
}

.dispDisd input{
    width: 100%;
    height: 42px;
    font-size: 15px;
    border: none;
    border-radius: 8px;
}
.widthSelected{
    width: 100px;
}
.min_ht{
    min-height: 450px;
}
.next_btnForm{
    background-color: #fff;
    color: #4361EE;
    font-weight: 600;
}
.next_btnFormContainer{
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.prev_btnFormContainer{
    position: absolute;
    bottom: 10px;
    left: 20px;
}

.selectServicesContent select{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 42px;
    padding: 0 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 15px;
}
.selectServicesContent select.xdForm{
    border: 1px solid #DC3545 !important;
}
.DForm textarea{
    border: 1px solid #D7D7DB;
    border-radius: 8px;
    height: 150px;
    padding: 10px;
    display: block;
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
    resize: none;
}
.pb_05{
    padding-bottom: 5px;
}
.BreadCrumbs{
    display: none;
}

@media (max-width:1000px) {
    .flex.xdccx{
        display: block !important;
    }
    .flex.xdccx .flex{
        padding-bottom: 20px;
    }
    .flex.xdccx .flex.pl_5{
        padding-left: 15px;
    }
}
@media (max-width: 960px) {
    .logo_container{
        max-width: 180px;
    }
}
@media (max-width: 890px) {
    .BreadCrumbs{
        display: block;
        padding: 7px 15px;
        font-weight: 500;
        font-size: 14px;
    }
    .NavLink{
        display: none;
    }
    .NavLink.xr{
        display: block;
        padding: 7px 25px;
    }
    .NavLinksContainer{
        justify-content: end;
    }
    
}

@media (max-width:500px) {
    .NavLinksContainer{
        max-width: 70px;
        margin-left: auto;
    }
    .NavLink.xr{
        display: none;
    }
    .NavLinksContainer .NavRequestServicesBtn{
        display: none;
    }
    .MyNav{
        padding: 2px 0 2px 12px;
    }
}











