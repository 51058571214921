.footer{
    padding: 0 0 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #cbdaec;
}
.flexFooter{
    display: flex;
    justify-content: space-between;
}

@media (max-width:500px) {
    .footer{
        grid-template-columns: 1fr;
    }
    .flexFooter .font_14{
        font-size: 12px;
    }
    .flexFooter{
        padding-bottom: 35px;
    }
    .zaFooter .left_auto{
        margin-left: initial;
    }
    .zaFooter .py_3.flex{
        display: block;
        text-align: center;
    }
}


















