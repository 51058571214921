.text_copy_synthax{
    padding-top: 5px;
    text-align: right;
}
/*  text muted 6C757D */
.navSideBar{
    position: fixed;
    width: 100px;
    height: 100%;
    /* background-color: aqua;     */
}
.sideBarIconcontainer{
    position: absolute;
    height: 500px;
    width: 70px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #4361EE;
    border-radius: 50px;
}
/* .bg_blur{
    background-color: #f1f1f1;
    min-height: 100vh;
} */
.mainContent{
    background-color: #fff;
    margin: 0 10px 10px 50px;
    border-radius: 15px;
    min-height: 97vh;
    padding-left: 50px;
}

.iconsConts{
    padding-top: 15px;
    text-align: center;
    margin-top: 25px;
}
.iconsConts div{
    margin-bottom: 25px;
}
.dashNavs{
    display: flex;
    padding: 15px 35px 15px 15px;
    border-radius: 10px;
    margin-right: 10px;
}

.iconser{
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.possitionIconizer{
    padding: 10px 0 0 10px ;
}
.dashNavLinks{
    padding: 14px 10px 0 10px ;
}
.mainContentDashboard{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 40px;
    padding: 20px 50px 20px 20px;
}
.plansContainerInfo{
    padding: 15px;
    border-radius: 20px;
}
.spand{
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 2px;
}
.spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #ddd9d9;
}
.subscribe_btn{
    background-color: #f1f1f1;
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}

.subscribe_btns{
    background-color: rgb(207,82,113,.1);
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
}
.recolor{
    color: rgb(207,82,113);
}

.plansContainerInfos{
    padding: 5px;
    border-radius: 20px;
}
.plansContainerInfos .p-2{
    border-radius: 15px;
    padding: 9px;
    /* border: 1px solid #f1f1f1; */
}
.bootDesign{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 40px;
    padding: 10px 50px 20px 20px;
}
.bootContent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.active .plansContainerInfo .spand div{
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #3491E8;
}
.active .subscribe_btn{
    background-color: rgb(52,145,232,.2);
}
.active .subscribe_btn .text-muted{
    color: rgb(52,145,232) !important;
}
.magazineDashboardContainer{
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-gap: 15px;
    margin-top: 10px;
    padding: 5px;
}
.ClassicItemsSales img{
    height: 118px;
    width: 100%;
    border-radius: 10px;
}
.eventDashboardContainer{
    display: grid;
    grid-template-columns: 2fr 8fr;
    grid-gap: 10px;
    margin-top: 10px;
    /* padding: 5px; */
}


.mainContentDashboardsubs{
    padding: 20px 50px 20px 20px;
}
.mainContentDashboardsubsContainer{
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: 40px;
}

.profileImageContainer img{
    border-radius: 10px;
}
.profileImageContainer{
    position: relative;
}
.padProfilexds{
    padding-top: 20px;
}
.profileSkills div{
    font-size: 14px;
}
.profilePhotosContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
.profileVideoContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}
.profileRemarkContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-top: 30px;
}
.PVContainer{
    border-radius: 10px;
    padding: 20px;
}
.DashboardTicketsContainer{
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-gap: 30px;
}
.tickets{
    padding-left: 10px;
    border-left: 5px solid #4361EE;
    
}
.tickets.rbk{
    border-left: 5px solid #F72585;
}
.colrbk{
    color: #F72585;
}
.collbk{
    color: #7209B7;
}
.tickets.lbk{
    border-left: 5px solid #7209B7;
}
.ticketsHover{
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.ticketsHover:hover{
    border: 1px solid #f1f1f1;
}
.projectDashContainers{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 13px 15px 13px 10px;
    max-width: 350px;
    width: 100%;
    display: inline-block;
}
.projectDashContainers select{
    width: 100%;
    border: none;
}
.progressContentContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 20px;
    margin: 30px 0;
}
.progressBarContainer{
    height: 4px;
    border-radius: 20px;
    background-color: #f1f1f1;
    margin: 15px 0;
}
.progressBar{
    background-color: #4361EE;
    border-radius: 20px;
    height: 100%;
}
.copyIt input{
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    /* max-width: 350px; */
    width: 90%;
}
.profileUserContainer{
    border-left: 5px solid #3491E8;
    padding: 40px;
    border-radius: 0 80px 80px 0;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    margin-top: 40px;

}
.innerUserProfile{
    border-radius: 0 50px 50px 0;
    padding: 20px;
}
.divideProfileInfo{
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 20px;
}

.requestOuterContainer{
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 6px;
    margin-bottom: 15px;
}
.requestInnerContainer{
    border-radius: 8px;
}
.reqGriders{
    display: grid;
    grid-template-columns: 80% 20%;
}
.selectedReqContainer{
    border-top: 1px solid #f1f1f1;
    padding: 2px 30px;
}
.requestInnerContainer:hover{
    background-color: #fcfcfc;
}
.padmeReq{
    padding: 10px 0 10px 30px;
}
.alignCenter{
    align-items: center;
    /* background-color: rgb(52,145,232,0.1); */
    border-radius: 0 45px 45px 0;

}
.alignCenter div{
    color: #3491E8;
}

.innerUserProfile input{
    border: none;
    border-bottom: 1px solid #f1f1f1;
    width: 90%;
    height: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
}
.SettingsSubmitBtn{
    color: #fff;
    background: #3491E8;
    border-radius: 10px;
    padding: 8px 25px;
    font-weight: 600;
    font-size: 15px;

}

.bread_crumbs #xd{
    overflow-y: auto;
    height: 95vh;
}
.bread_crumbs{
    /* position: fixed; */
    width: 100%;
    height: 100vh;
    /* background-color: rgba(0,0,0,.6); */
    z-index: 500;
}
.inner_bread_Crumbs{
    padding: 0 20px 0 0;
    
}

.drop_down_container{
    background-color: #040D36;
    padding: 50px 0 0 0;
    animation: dropDown 0.3s forwards ;
    position: relative;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin-left: auto;
}
.navListItems a,.navListItems samp{
    color: #fff;
    font-size: 15px;
    font-family: Font600;
}
.navListItems .xdd.center{
    padding: 15px 0;
}

.flex.xc{
    align-items: center;
    padding-right: 3px;
}
.left_auto.xcd{
    padding-bottom: 3px;
}
.priceContainer_Displayer{
    margin: 15px 0;
    font-weight: 600;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
}
.primax{
    /* padding-right: 5px; */
    font-size: 11px;
}
.orderSummarY .selectedReqContainer{
    padding: 2px 10px;

}
.orderSummarY .padmeReq{
    padding: 10px 0 10px 10px;
}
.bg-muted{
    background-color: #6C757D;
}
.selectorSm{
    width: 13px;
    height: 13px;
    border: 1px solid #6C757D;
    border-radius: 3px;
    cursor: pointer;
}
.selectorSm.bg-primary{
    border: 1px solid #3B71CA !important;
}
.EmailSetUpContainer{
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #a5acb2;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}
@media (max-width:800px) {
    .DashboardTicketsContainer{
        grid-template-columns: 1fr;
    }
    .orderSummarY{
        border-top: 2px solid #f1f1f1;
        padding-top: 20px;
    }
}
@media (max-width: 788px) {
    .dashNavLinks{
        display: none;
    }
    .dashNavs{
        padding: 15px 0 15px 15px;
        margin-right: 0;
    }
}
@media (max-width: 700px) {
    .alignCenter .w-100{
        font-size: 9px;
        padding-right: 5px;
    }
}
@media (max-width:500px) {
    .selectedReqContainer{
        padding: 2px 10px;
    
    }
    .padmeReq{
        padding: 10px 0 10px 10px;
    }
    .priceContainer_Displayer .xc{
        font-size: 14px;
    }
    .priceContainer_Displayer .xc.font_12{
        font-size: 11px;
    }
    
    .priceContainer_Displayer button{
        font-size: 12px !important;
    }
    .navSideBar{
        display: none;
    }
    .mainContent{
        margin: 0 10px 10px 10px;
        padding-left: 0;
    }
    .mainContentDashboardsubs{
        padding: 20px 15px;
    }
    .profileUserContainer{
        padding: 20px;
    }
    .requestOuterContainer .padmeReq .font_14{
        font-size: 12px;
    }
    .requestOuterContainer .padmeReq .font_12{
        font-size: 10px;
    }
    .profileUserContainer .bold8{
        font-size: 11px;
    }
}














